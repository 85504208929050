
































import { Component, Vue } from "vue-property-decorator";

@Component
export default class SendMail extends Vue {
  msg = "";
  emailAddress = "";
  loading = false;
  status = "";
  subject = "";
  responseBody = "";
  submitButtonState = false;

  get disableSubmit(): boolean {
    return (
      !this.validateEmail ||
      !this.subject.length ||
      !this.msg.length ||
      this.loading
    );
  }

  get validateEmail(): boolean {
    return (
      !!this.emailAddress.length &&
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailAddress)
    );
  }

  sendMail(): void {
    this.submitButtonState = true;
    this.status = "Sending...";
    this.loading = true;
    fetch(
      `https://word-report-svc.herokuapp.com/sendMail?sendto=${encodeURIComponent(
        // TODO: save email as Vue.prototype?
        this.emailAddress
      )}&message=${encodeURIComponent(this.msg)}&subject=${this.subject}`
    )
      .then((res) => {
        res.json().then((content) => {
          console.log(content);
          this.status = content.message;
        });
      })
      .catch((err) => {
        this.status = JSON.stringify(err, null, 2);
      })
      .finally(() => {
        this.loading = false;
        this.submitButtonState = false;
      });
  }
}
